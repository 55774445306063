import React, { useEffect, useRef, useState } from "react";
import { Input, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const DropdownComponent = ({
  list = [],
  value = "",
  onChange = () => {},
  placeholder = "-- Select Category --",
  isOpen = false,
  toggleDropdown = () => {},
}) => {
  console.log(value, "inside dropdown component>>>>>>>");
  const [selectedValue, setSelectedValue] = useState(value);
  const [searchQuery, setSearchQuery] = useState("");
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (isOpen) {
          toggleDropdown();
        }
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [list]);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const filteredList = searchQuery
    ? list.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : list;

  const handleSelect = (item) => {
    setSelectedValue(item.label);
    onChange(item);
    toggleDropdown();
  };

  return (
    <div ref={ref} className="position-relative">
      <FormGroup>
        <div
          className="form-control d-flex align-items-center justify-content-between"
          style={{
            cursor: "pointer",
            backgroundColor: "#fff",
            border: "1px solid #ced4da",
            borderRadius: "4px",
            padding: "0.75rem",
          }}
          // onClick={() => setIsDropdown((prev) => !prev)}
          onClick={toggleDropdown}
        >
          <span>{selectedValue || placeholder}</span>
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
        </div>
      </FormGroup>
      {isOpen && (
        <div
          className="dropdown-menu show w-100 p-2 border shadow rounded"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          <Input
            type="text"
            placeholder="Search..."
            className="form-control mb-2"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {filteredList.length > 0 ? (
            filteredList.map((item, idx) => (
              <div
                key={`item-${idx}`}
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => handleSelect(item)}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div className="dropdown-item text-muted">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
