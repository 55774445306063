import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import CreateSalesUserModal from "components/Modals/SalesUserModals/CreateSalesUserModal";
import { getSales } from "store/actions/salesAction";
import { salesSearchByName } from "store/actions/salesAction";
import UpdateSalesUserModal from "components/Modals/SalesUserModals/UpdateSalesUserModal";
import { updateSalesUser } from "store/actions/salesAction";
import { toast } from "react-toastify";
const SalesUsers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { sales, totalPages, getSalesLoader } = useSelector(
    (state) => state.sales
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [userSearchByName, setUserSearchByName] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [updateSalesModal, setUpdateSalesModal] = useState(false);
  console.log(updateSalesModal, "updateSalesModal>>>>>>>>>>>>>>>>>>>");
  const handleUpdateSalesModal = () => {
    setUpdateSalesModal(!updateSalesModal);
  };
  const [editData, setEditData] = useState("");
  // Function to toggle the modal

  const itemsPerPage = 10;
  const addSalesUserModal = () => {
    setAddModal(!addModal);
  };
  const handleOnSubmit = () => {
    setCurrentPage(1);
    dispatch(salesSearchByName(userSearchByName));
  };
  const handleClearSearch = () => {
    setUserSearchByName("");
    dispatch(getSales());
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handleStatus = (status, id) => {
    const user = {
      status: `${!status}`,
    };
    dispatch(
      updateSalesUser(id, user, () => {
        toast.success(!status ? "Sales User Enabled!" : "Sales User Disabled!");
        setUserSearchByName("");
        dispatch(getSales(currentPage, itemsPerPage));
      })
    );
  };
  // format the date and time

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedDate;
  };
  useEffect(() => {
    dispatch(getSales(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <CreateSalesUserModal
        modal={addModal}
        toggle={addSalesUserModal}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      <UpdateSalesUserModal
        modal={updateSalesModal}
        toggle={handleUpdateSalesModal}
        setUserSearchByName={setUserSearchByName}
        data={editData}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Sales Users</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="userSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={userSearchByName}
                          onChange={(e) => setUserSearchByName(e.target.value)}
                          // bsSize="sm"
                          // className="py-3"
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Name
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getSalesLoader}
                        onClick={handleOnSubmit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getSalesLoader || !userSearchByName}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        size="sm"
                        onClick={addSalesUserModal}
                      >
                        Create
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {!getSalesLoader ? (
                  <tbody>
                    {sales && sales.length > 0 ? (
                      sales?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{user?.id}</td>
                            <td>
                              {user?.firstName} {user?.lastName}
                            </td>
                            <td>{formatTimestamp(user?.createdAt)}</td>
                            <td>{formatTimestamp(user?.updatedAt)}</td>
                            <td>{user?.email}</td>
                            <td>
                              {user?.role === "resolutionTeam" ? (
                                ""
                              ) : (
                                <td>
                                  <Button
                                    onClick={() => {
                                      handleUpdateSalesModal();
                                      setEditData(user);
                                    }}
                                    color="primary"
                                    size="sm"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Button>
                                  <Button
                                    color={user?.status ? "success" : "danger"}
                                    size="sm"
                                    onClick={() =>
                                      handleStatus(user?.status, user?.id)
                                    }
                                  >
                                    {user?.status ? "Enabled" : "Disabled"}{" "}
                                    <i
                                      className={`fa fa-${
                                        user?.status
                                          ? "check-circle"
                                          : "times-circle"
                                      }`}
                                    />
                                  </Button>
                                </td>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No Users Found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SalesUsers;
