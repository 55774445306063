/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const WORKTYPES = "work-type";
const GET_SEARCH_BY_TITLE = "work-type/search";
export default {
  getWorkTypes(page, limit) {
    return Repository.get(`${WORKTYPES}?page=${page}&limit=${limit}`);
  },
  addWorkType(payload) {
    return Repository.post(`${WORKTYPES}`, payload);
  },
  deleteWorkType(id) {
    return Repository.delete(`${WORKTYPES}/${id}`);
  },
  updateWorkType(id, payload) {
    return Repository.put(`${WORKTYPES}/${id}`, payload);
  },
  workSearchByTitle(title) {
    const requestBody = {
      text: title,
    };
    return Repository.post(`${GET_SEARCH_BY_TITLE}`, requestBody);
  },
};
