import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { equipmentSearchByTitleAction } from "store/actions/equipmentAction";
import { deleteEquipment } from "store/actions/equipmentAction";
import { getEquipmentsAction } from "store/actions/equipmentAction";
import { getWorkTypeAction } from "store/actions/workTypeActions";
import { deleteWorkType } from "store/actions/workTypeActions";

const DeleteEquipmentWorkModal = ({
  SearchTitle,
  modal,
  toggle,
  id,
  type,
  currentPage,
  itemsPerPage,
}) => {
  const dispatch = useDispatch();
  const { deleteWorkTypeLoader } = useSelector((state) => state.worktypes);
  const { deleteEquipmentLoader } = useSelector((state) => state.equipments);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          {type === "equipment" ? "Delete Equipment" : "Delete WorkType"}
        </ModalHeader>
        <ModalBody>Are you sure you want to delete ?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={deleteEquipmentLoader || deleteWorkTypeLoader}
            onClick={() => {
              if (type === "equipment") {
                dispatch(
                  deleteEquipment(id, () => {
                    toggle();
                    if (!SearchTitle) {
                      dispatch(getEquipmentsAction(currentPage, itemsPerPage));
                    } else {
                      dispatch(equipmentSearchByTitleAction(SearchTitle));
                    }
                  })
                );
              } else {
                dispatch(
                  deleteWorkType(id, () => {
                    toggle();
                    if (!SearchTitle) {
                      dispatch(getWorkTypeAction(currentPage, itemsPerPage));
                    }
                  })
                );
              }
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteEquipmentWorkModal;
