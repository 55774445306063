import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const workType = RepositoryFactory.get("workType");
export const getWorkTypeAction = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_WORKTYPE_LOADER",
      payload: true,
    });
    const { data } = await workType.getWorkTypes(page, limit);
    dispatch({
      type: "GET_WORKTYPES",
      payload: data,
    });
    dispatch({
      type: "GET_WORKTYPE_LOADER",
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: "GET_WORKTYPE_LOADER",
      payload: false,
    });
  }
};

export const addNewWorkType =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_WORKTYPE_LOADER",
        payload: true,
      });

      const { data } = await workType.addWorkType(payload);
      toast.success(data?.message);
      dispatch({
        type: "ADD_WORKTYPE_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "ADD_WORKTYPE_LOADER",
        payload: false,
      });
    }
  };

export const deleteWorkType =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_WORKTYPE_LOADER",
        payload: true,
      });
      const { data } = await workType.deleteWorkType(id);

      dispatch({
        type: "DELETE_WORKTYPE_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "WORKTYPE_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "DELETE_WORKTYPE_LOADER",
        payload: false,
      });
    }
  };

export const updateWorkType =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_WORKTYPE_LOADER",
        payload: true,
      });

      const { data } = await workType.updateWorkType(id, payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_WORKTYPE_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_WORKTYPE_LOADER",
        payload: false,
      });
    }
  };
export const workTypeSearchByTitleAction = (title) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_WORKTYPE_LOADER",
      payload: true,
    });
    const { data } = await workType.workSearchByTitle(title);
    if (data?.success) {
      dispatch({
        type: "GET_WORKTYPE_SEARCH_BY_TITLE",
        payload: data,
      });
      dispatch({
        type: "GET_WORKTYPE_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_WORKTYPE_LOADER",
      payload: false,
    });
  }
};
