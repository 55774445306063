const initState = {
  allServices: [],
  getServicesLoader: false,
  deleteServiceLoader: false,
  totalPages: null,
};

const serviceReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_SERVICES":
      return {
        ...state,
        allServices: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_SERVICES_LOADER":
      return {
        ...state,
        getServicesLoader: payload,
      };
    case "DELETE_SERVICE_LOADER":
      return {
        ...state,
        deleteServiceLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default serviceReducer;
