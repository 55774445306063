import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const equipment = RepositoryFactory.get("equipments");

export const getEquipmentsAction = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_EQUIPMENT_LOADER",
      payload: true,
    });
    const { data } = await equipment.getEquipments(page, limit);
    dispatch({
      type: "GET_EQUIPMENTS",
      payload: data,
    });
    dispatch({
      type: "GET_EQUIPMENT_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_EQUIPMENT_LOADER",
      payload: false,
    });
  }
};

export const addNewEquipment =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_EQUIPMENT_LOADER",
        payload: true,
      });

      const { data } = await equipment.addEquipment(payload);
      toast.success(data?.message);
      dispatch({
        type: "ADD_EQUIPMENT_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "ADD_EQUIPMENT_LOADER",
        payload: false,
      });
    }
  };

export const deleteEquipment =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_EQUIPMENT_LOADER",
        payload: true,
      });
      const { data } = await equipment.deleteEquipment(id);

      dispatch({
        type: "DELETE_EQUIPMENT_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "EQUIPMENT_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "DELETE_EQUIPMENT_LOADER",
        payload: false,
      });
    }
  };

export const updateEquipment =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_EQUIPMENT_LOADER",
        payload: true,
      });

      const { data } = await equipment.updateEquipment(id, payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_EQUIPMENT_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_EQUIPMENT_LOADER",
        payload: false,
      });
    }
  };
export const equipmentSearchByTitleAction = (title) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_EQUIPMENT_LOADER",
      payload: true,
    });
    const { data } = await equipment.equipmentSearchByTitle(title);

    if (data?.success) {
      dispatch({
        type: "GET_EQUIPMENT_SEARCH_BY_TITLE",
        payload: data,
      });
      dispatch({
        type: "GET_EQUIPMENT_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_EQUIPEMENT_LOADER",
      payload: false,
    });
  }
};
