const initialState = {
  categories: [],
  countriesList: [],
  states: [],
  cities: [],
  isLoading: false,
};

const countryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_COUNTRY_LIST": {
      const mappedCountries = payload?.data?.map((country) => ({
        value: country.countryName,
        label: country.countryName,
        countryCode: country.countryCode,
        currencyCode: country.currencyCode,
        languageCode: country.languageCode,
        ...country,
      }));

      mappedCountries.sort((a, b) => a.label.localeCompare(b.label));

      return {
        ...state,
        countriesList: mappedCountries,
      };
    }

    case "GET_COUNTRY_STATES": {
      const mappedStates = payload?.data?.map((item) => ({
        label: item.name,
        value: item.name,
        state_code: item.state_code,
        id: item?.id,
      }));

      mappedStates.sort((a, b) => a.label.localeCompare(b.label));

      return {
        ...state,
        states: mappedStates,
      };
    }

    case "GET_COUNTRY_CITIES": {
      const mappedCities = payload?.data?.map((item) => ({
        label: item.name,
        value: item.name,
      }));

      mappedCities.sort((a, b) => a.label.localeCompare(b.label));

      return {
        ...state,
        cities: mappedCities,
      };
    }

    case "GET_CATEGORIES": {
      const categories = payload?.data || [];

      const augmentedCategories = categories.map((category) => ({
        id: category._id,
        value: category.name,
        label: category.name,
      }));

      augmentedCategories.sort((a, b) => a.label.localeCompare(b.label));

      return {
        ...state,
        categories: augmentedCategories,
      };
    }

    default:
      return state;
  }
};

export default countryReducer;
