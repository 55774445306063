import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { getSales } from "store/actions/salesAction";
import { updateSalesUser } from "store/actions/salesAction";

const UpdateSalesUserModal = ({
  modal,
  toggle,
  setUserSearchByName,
  data,
  currentPage,
  itemsPerPage,
}) => {
  const dispatch = useDispatch();
  const { updateSalesLoader } = useSelector((state) => state.sales);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  //   const generateCode = () => {
  //     return Math.floor(1000 + Math.random() * 9000).toString();
  //   };

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       const newCode = generateCode();
  //       setUser((prevUser) => ({ ...prevUser, code: newCode }));
  //       console.log("New code generated:", newCode); // Log for debugging
  //     }, 60000); // 60000ms = 1 minute
  //     const initialCode = generateCode();
  //     setUser((prevUser) => ({ ...prevUser, code: initialCode }));
  //     return () => clearInterval(interval);
  //   }, []);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!user?.firstName || !user?.lastName || !user?.email) {
      toast.error("Please fill in all fields before submitting");
      return;
    }

    dispatch(
      updateSalesUser(data?.id, user, () => {
        toggle();
        setUser({});
        setUserSearchByName("");
        toast.success("Sales User Updated Successfully!");
        dispatch(getSales(currentPage, itemsPerPage));
      })
    );
  };

  useEffect(() => {
    if (data) {
      setUser({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        password: data?.password,
      });
    }
  }, [data]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Update Sales User</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">First Name</Label>
                <FormGroup>
                  <Input
                    value={user?.firstName}
                    onChange={(e) => handleChange(e)}
                    name="firstName"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Last Name</Label>
                <FormGroup>
                  <Input
                    value={user?.lastName}
                    onChange={(e) => handleChange(e)}
                    name="lastName"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Email</Label>
                <FormGroup>
                  <Input
                    readOnly
                    value={user?.email}
                    onChange={(e) => handleChange(e)}
                    name="email"
                    placeholder="Email"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <div>
                  <Label className="font-weight-bold">Password</Label>
                  <div className="input-group">
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={user?.password}
                      onChange={(e) => handleChange(e)}
                      placeholder="Change Password"
                      className="pr-10"
                    />
                    <div className="input-group-append">
                      <span
                        onClick={handlePasswordToggle}
                        className="input-group-text cursor-pointer"
                      >
                        {showPassword ? (
                          <i className="fas fa-eye text-gray-500"></i>
                        ) : (
                          <i className="fas fa-eye-slash text-gray-500"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end align-items-center">
              <Button
                color="primary"
                type="submit"
                disabled={updateSalesLoader}
              >
                {updateSalesLoader ? <Spinner size="sm" /> : "Update"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateSalesUserModal;
