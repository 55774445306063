import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const workOrder = RepositoryFactory.get("workOrder");
export const getPostedWorkOrder = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: true,
    });
    const { data } = await workOrder.getWorkOrder(page, limit);
    dispatch({
      type: "GET_WORK_ORDER",
      payload: data,
    });
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: false,
    });
  }
};
export const deleteWorkOrder =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_WORK_ORDER_LOADER",
        payload: true,
      });
      const { data } = await workOrder.deleteWorkOrder(id, {
        reason: "Deleted By Tealpot Administration",
      });

      dispatch({
        type: "DELETE_WORK_ORDER_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "WORK_ORDER_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "DELETE_WORK_ORDER_LOADER",
        payload: false,
      });
    }
  };
export const searchWorkOrder = (payload, page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: true,
    });
    const { data } = await workOrder.searchWorkOrder(payload, page, limit);
    dispatch({
      type: "GET_WORK_ORDER",
      payload: data,
    });
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: false,
    });
  }
};
export const filterWorkOrder = (payload, page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: true,
    });
    console.log("Called", payload);
    const { data } = await workOrder.filterWorkOrder(payload, page, limit);
    dispatch({
      type: "GET_WORK_ORDER",
      payload: data,
    });
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_WORK_ORDER_LOADER",
      payload: false,
    });
  }
};
