import Repository from "./Repository";
const GET_ALL = "user/";
const EXPORT_ALL = "user/exports";
const GET_USERS_SEARCH_BY_NAME = "user/byName/";
const USER_SEARCH = "/user/search-user";
const USER_SEARCH_SUPPORT_CHAT = "/user/search";
const SUSPEND_USER = "user";
const ACTIVATE_USER = "user";
const GET_SINGLE = "user";
const UPDATE_PASS = "/admin-auth/update-password";
const GET_FILTER = "/user/filter/";
export default {
  getAllUsers(page, limit) {
    return Repository.get(`${GET_ALL}?page=${page}&limit=${limit}`);
  },
  searchUser(query, page) {
    const requestBody = {
      text: query,
    };
    return Repository.post(`${USER_SEARCH}?page=${page}limit=10`, requestBody);
  },
  searchUserInSupportChat(data) {
    return Repository.post(`${USER_SEARCH}`, data);
  },
  getSingleUser(id) {
    return Repository.get(`${GET_SINGLE}/${id}`);
  },
  suspendUser(id, payload) {
    return Repository.post(`${SUSPEND_USER}/${id}/suspend`, payload);
  },
  activateUser(id, payload) {
    return Repository.put(`${ACTIVATE_USER}/${id}/active`, payload);
  },
  updatePassword(payload) {
    return Repository.put(`${UPDATE_PASS}`, payload);
  },

  applyFilter(id) {
    return Repository.get(`${GET_FILTER}${id}`);
  },
  exportUsers(payload) {
    return Repository.get(
      `${EXPORT_ALL}?startDate=${payload.startDate}&endDate=${payload.endDate}`
    );
  },
};
