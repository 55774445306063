import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const service = RepositoryFactory.get("services");
export const getServiceAction = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: true,
    });
    const { data } = await service.getServices(page, limit);
    dispatch({
      type: "GET_SERVICES",
      payload: data,
    });
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: false,
    });
  }
};
export const deleteService =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_SERVICE_LOADER",
        payload: true,
      });
      const { data } = await service.deleteService(id);

      dispatch({
        type: "DELETE_SERVICE_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "SERVICE_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "DELETE_SERVICE_LOADER",
        payload: false,
      });
    }
  };
export const SearchService = (payload, page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: true,
    });
    const { data } = await service.searchService(payload, page, limit);
    dispatch({
      type: "GET_SERVICES",
      payload: data,
    });
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: false,
    });
  }
};
export const filterService = (payload, page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: true,
    });
    const { data } = await service.filterService(payload, page, limit);
    dispatch({
      type: "GET_SERVICES",
      payload: data,
    });
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_SERVICES_LOADER",
      payload: false,
    });
  }
};
