const initState = {
  sales: [],
  totalPages: null,
  addSalesLoader: false,
  updateSalesLoader: false,
  getSalesLoader: false,
};
const salesReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_SALES":
      return {
        ...state,
        sales: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_SALES_BY_NAME":
      return {
        ...state,
        sales: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_SALES_LOADER":
      return {
        ...state,
        getSalesLoader: payload,
      };
    case "ADD_SALES_LOADER":
      return {
        ...state,
        addSalesLoader: payload,
      };
    case "UPDATE_SALES_LOADER":
      return {
        ...state,
        updateSalesLoader: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default salesReducer;
