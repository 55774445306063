/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const SERVICES = "service";
const CATEGORY = "category";
const COUNTRY = "/global/country";
const CITIES = "/global/cities-by-state";
const COUNTRYSTATES = "/global/states-by-country";
export default {
  getServices(page, limit) {
    return Repository.get(`${SERVICES}/admin/all?page=${page}&limit=${limit}`);
  },
  deleteService(id) {
    return Repository.delete(`${SERVICES}/${id}`);
  },
  searchService(payload, page, limit) {
    return Repository.post(
      `${SERVICES}/admin/search?page=${page}&limit=${limit}`,
      payload
    );
  },
  filterService(payload, page, limit) {
    return Repository.post(
      `${SERVICES}/admin/filter?page=${page}&limit=${limit}`,
      payload
    );
  },
  getCategories() {
    return Repository.get(`${CATEGORY}`);
  },
  getCountries() {
    return Repository.get(`${COUNTRY}`);
  },
  getCities(payload) {
    return Repository.post(`${CITIES}`, payload);
  },
  getCountryStates(payload) {
    return Repository.post(`${COUNTRYSTATES}`, payload);
  },
};
