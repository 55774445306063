/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Users from "views/Users";
import Orders from "views/Orders";
import Disputes from "views/Disputes";
import SingleUserDetails from "views/singleUserDetails";
import DisputesDetails from "views/DisputesDetails";
import Policies from "views/Policies";
import Tickets from "views/Tickets";
import TicketsDetails from "views/TicketsDetails";
import PreviousTaxes from "views/PreviousTaxes";
import ForgetPassword from "views/auth/ForgetPassword";
import SupportTeam from "views/SupportTeam";
import Resolution from "views/Resolution";
import Blogs from "views/Blogs";
import AddBlog from "views/Blog/AddBlog";
import EditBlog from "views/Blog/Editblog";
import Profile from "views/Profile";
import ResetPassword from "views/auth/ResetPassword";
import VerifyEmail from "views/auth/VerifyEmail";
import CountriesSupport from "views/CountriesSupport";
import Message from "views/Message";
import Payments from "views/Payments";
import Categories from "views/Categories";
import SupportChat from "views/SupportChat";
import Report from "views/Report";
import ReportDetails from "views/ReportDetails";
import JobDeletion from "views/JobDeletion";
import CovidTests from "views/CovidTests";
import BackgroundChecks from "views/BackgroundChecks";
import Admins from "views/Admins";
import CheckrReport from "views/CheckrReport";
import HelpArticles from "views/HelpArticles/HelpArticles";
import AddArticle from "views/HelpArticles/AddArticle";
import EditArticle from "views/HelpArticles/EditArticle";
import Skills from "views/Skills";
import NameRequest from "views/NameRequest";
import SalesUsers from "views/SalesUsers";
import Equipments from "views/Equipments";
import WorkTypes from "views/WorkTypes";
import Services from "views/Services";
import PostedWorkOrders from "views/PostedWorkOrders";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/admins",
    name: "Admins",
    icon: "ni ni-settings text-primary",
    component: Admins,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-badge text-primary",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-circle-08 text-primary",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/sales-users",
    name: "Sales Users",
    icon: "ni ni-circle-08 text-primary",
    component: SalesUsers,
    layout: "/admin",
  },

  {
    path: "/resolution",
    name: "Resolution Member",
    icon: "ni ni-badge text-primary",
    component: Resolution,
    layout: "/admin",
  },
  {
    path: "/support-team",
    name: "Support Team",
    icon: "fa fa-life-ring text-primary",
    component: SupportTeam,
    layout: "/admin",
  },
  {
    path: "/posted-work-orders",
    name: "Posted Work Orders",
    icon: "ni ni-cart text-primary",
    component: PostedWorkOrders,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-cart text-primary",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/message",
    name: "Public Message",
    icon: "fa fa-envelope text-primary",
    component: Message,
    layout: "/admin",
  },
  {
    path: "/single-user-details/:id",
    name: "User Details",
    component: SingleUserDetails,
    isMenu: false,
    layout: "/admin",
  },
  {
    path: "/disputes",
    name: "Disputes",
    icon: "fa fa-bolt text-primary",
    component: Disputes,
    layout: "/admin",
  },
  {
    path: "/disputes-details/:id",
    name: "Disputes Details",
    icon: "ni ni-tv-2 text-primary",
    component: DisputesDetails,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/tickets",
    name: "Tickets",
    icon: "ni ni-single-copy-04 text-primary",
    component: Tickets,
    layout: "/admin",
  },
  {
    path: "/ticket-details/:id",
    name: "Tickets Details",
    icon: "ni ni-tv-2 text-primary",
    component: TicketsDetails,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/policies",
    name: "Policies",
    icon: "ni ni-collection text-primary",
    component: Policies,
    layout: "/admin",
  },

  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-box-2 text-primary",
    component: Categories,
    layout: "/admin",
  },

  {
    path: "/blogs",
    name: "Blogs",
    icon: "ni ni-hat-3 text-primary",
    component: Blogs,
    layout: "/admin",
  },
  {
    path: "/addBlog",
    name: "Add Blog",
    icon: "ni ni-hat-3 text-primary",
    component: AddBlog,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/editBlog/:id",
    name: "Edit Blog",
    icon: "ni ni-hat-3 text-primary",
    component: EditBlog,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-notification-70 text-primary",
    component: Report,
    layout: "/admin",
  },
  {
    path: "/checkr-reports",
    name: "Checkr Reports",
    icon: "ni ni-notification-70 text-primary",
    component: CheckrReport,
    layout: "/admin",
  },
  {
    path: "/report-details/:id",
    name: "Reports Details",
    icon: "ni ni-tv-2 text-primary",
    component: ReportDetails,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "fa fa-usd",
    component: Payments,
    layout: "/admin",
  },
  {
    path: "/skills",
    name: "Skills",
    icon: "fas fa-medal",
    component: Skills,
    layout: "/admin",
  },
  {
    path: "/services",
    name: "Services",
    icon: "fas fa-briefcase",
    component: Services,
    layout: "/admin",
  },
  {
    path: "/equipments",
    name: "Equipments",
    icon: "fas fa-tools",
    component: Equipments,
    layout: "/admin",
  },
  {
    path: "/worktypes",
    name: "Work Types",
    icon: "fas fa-tasks",
    component: WorkTypes,
    layout: "/admin",
  },
  {
    path: "/name-requests",
    name: "Name Requests",
    icon: "fas fa-paper-plane",
    component: NameRequest,
    layout: "/admin",
  },
  {
    path: "/job-deletion",
    name: "Deleted Jobs",
    icon: "fa fa-trash",
    component: JobDeletion,
    layout: "/admin",
  },
  {
    path: "/countries-support",
    name: "Countries Support",
    icon: "fa fa-globe text-primary",
    component: CountriesSupport,
    layout: "/admin",
  },

  {
    path: "/supportchat",
    name: "Support Chat",
    icon: "ni ni-chat-round text-primary",
    component: SupportChat,
    layout: "/admin",
  },
  {
    path: "/covid-tests",
    name: "Covid Tests",
    icon: "fa fa-soli fa-virus-covid text-primary",
    component: CovidTests,
    layout: "/admin",
  },
  {
    path: "/help-articles",
    name: "Help Articles",
    icon: "ni ni-hat-3 text-primary",
    component: HelpArticles,
    layout: "/admin",
  },
  {
    path: "/add-article",
    name: "Add Article",
    icon: "ni ni-hat-3 text-primary",
    component: AddArticle,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/edit-article/:id",
    name: "Edit Article",
    icon: "ni ni-hat-3 text-primary",
    component: EditArticle,
    layout: "/admin",
    isMenu: false,
  },
  // {
  //   path: "/background-check",
  //   name: "Background Checks",
  //   icon: "fa fa-flag-checkered text-primary",
  //   component: BackgroundChecks,
  //   layout: "/admin",
  // },

  {
    path: "/previous-taxes/:id",
    name: "Previous Taxes",
    icon: "ni ni-tv-2 text-primary",
    component: PreviousTaxes,
    layout: "/admin",
    isMenu: false,
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/forgetPassword",
    name: "Forget Password",
    icon: "ni ni-circle-08 text-pink",
    component: ForgetPassword,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: ResetPassword,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/verifyEmail",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: VerifyEmail,
    layout: "/auth",
    isMenu: false,
  },
];
export default routes;
