import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const sales = RepositoryFactory.get("sales");

export const getSales = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: "GET_SALES_LOADER", payload: true });
    const { data } = await sales.getSales(page, limit);

    dispatch({
      type: "GET_SALES",
      payload: data,
    });
    dispatch({ type: "GET_SALES_LOADER", payload: false });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({ type: "GET_SALES_LOADER", payload: false });
  }
};

export const salesSearchByName = (name) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SALES_LOADER",
      payload: true,
    });
    const { data } = await sales.salesSearchByName(name);
    console.log("adminSearchByName_data: ", data);
    dispatch({
      type: "GET_SALES_BY_NAME",
      payload: data,
    });
    dispatch({
      type: "GET_SALES_LOADER",
      payload: false,
    });
  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_SALES_LOADER",
      payload: false,
    });
  }
};

export const addSalesUser =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_SALES_LOADER",
        payload: true,
      });

      const { data } = await sales.addSales(payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "ADD_SALES_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "ADD_SALES_LOADER",
        payload: false,
      });
    }
  };

export const updateSalesUser =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log("id, payload: ", id, payload);
    try {
      dispatch({
        type: "UPDATE_SALES_LOADER",
        payload: true,
      });

      const { data } = await sales.updateSales(id, payload);

      //   toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_SALES_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_SALES_LOADER",
        payload: false,
      });
    }
  };
