import Repository from "./Repository";
const GET_ALL = "/admin/sales";
const GET_SALES_SEARCH_BY_NAME = "/admin/sales/search";
const ADD_SALES = "admin/";
const UPDATE_SALES = "admin/";

export default {
  getSales(page, limit) {
    return Repository.get(`${GET_ALL}?page=${page}&limit=${limit}`);
  },
  salesSearchByName(name) {
    const requestBody = {
      text: name,
    };
    return Repository.post(`${GET_SALES_SEARCH_BY_NAME}`, requestBody);
  },
  addSales(payload) {
    return Repository.post(`${ADD_SALES}`, payload);
  },
  updateSales(id, payload) {
    return Repository.put(`${UPDATE_SALES}/${id}`, payload);
  },
};
