import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const convertKilometersToMiles = (kilometers) => {
  const cleanInput = kilometers && kilometers.toString().replace(/,/g, "");
  let km = parseFloat(cleanInput) * 0.621371;
  return km.toFixed(2);
};
export const getCategoryLabel = (id, categories) =>
  categories?.find((item) => item?.id === id)?.label || "";
const Filters = ({ appliedFilters }) => {
    console.log(appliedFilters,"appliedFilters>>>>>>>>>>>");
  const { categories } = useSelector((state) => state.countries);
  const { user } = useSelector((state) => state.auth);
  const [filterLabels, setFilterLabels] = useState(null);
  console.log(filterLabels, "filterLabels>>>>>>>>>>>>");

  useEffect(() => {
    if (appliedFilters && categories) {
      const categoryLabel = getCategoryLabel(
        appliedFilters.category,
        categories
      );

      setFilterLabels({ ...appliedFilters, category: categoryLabel });
    } else {
      setFilterLabels(null);
    }
  }, [appliedFilters, categories]);

  return (
    filterLabels &&
    Object.entries(filterLabels)
      .filter(([key, value]) => value)
      .map(([key, value]) => (
        <div
          key={key}
          className="d-inline-flex align-items-center bg-light text-muted rounded-pill py-1 px-3 m-1"
          style={{ fontSize: "0.75rem" }}
        >
          <span className="fw-bold text-dark">{formatFilterKey(key)}:</span>
          <span className="ms-1 text-dark">
            {formatFilterValue(key, value, user)}
          </span>
        </div>
      ))
  );
};
const formatFilterKey = (key) => key.replace(/([A-Z])/g, " $1").toLowerCase();

const formatFilterValue = (key, value, user) => {
  const hasDash = value && value.toString().includes("-");
  if (hasDash) {
    const hasOneDash = value && value.split("-").length - 1 === 1;
    if (hasOneDash) {
      const [from, to] = value.split("-");
      if (from && to) {
        return `from $${from} to $${to}`;
      } else if (from) {
        return `from $${from} to max`;
      } else if (to) {
        return `from 0 to $${to}`;
      }
    }
  }

  if (key === "jobSuccess") {
    return `${value}%`;
  }

  if (key === "maxPrice" || key === "minPrice") {
    return `$${value}`;
  }
  if (key === "distance") {
    if (user?.country === "US") {
      let convertedDistance = convertKilometersToMiles(value.toString());
      return `${convertedDistance} miles`;
    }
  }

  return value;
};

export default Filters;
