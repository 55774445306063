import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const service = RepositoryFactory.get("services");
export const getCountries = (page, limit) => async (dispatch) => {
  try {
    const response = await service.getCountries(page, limit);
    dispatch({
      type: "GET_COUNTRY_LIST",
      payload: response.data,
    });
  } catch (err) {
    toast.error("Failed to fetch countries.");
    console.error(err);
  }
};
export const getCities = (data) => async (dispatch) => {
  try {
    let payload = {
      state_id: data.state,
    };
    const response = await service.getCities(payload);
    dispatch({
      type: "GET_COUNTRY_CITIES",
      payload: response.data,
    });
  } catch (err) {
    toast.error("Failed to fetch cities.");
    console.error(err);
  }
};
export const getCategories = () => async (dispatch) => {
  try {
    const response = await service.getCategories();
    dispatch({
      type: "GET_CATEGORIES",
      payload: response.data,
    });
  } catch (err) {
    toast.error("Failed to fetch cities.");
    console.error(err);
  }
};
export const getCountryStates = (data) => async (dispatch) => {
  try {
    let payload = {
      country_code: data.country,
    };
    const response = await service.getCountryStates(payload);
    dispatch({
      type: "GET_COUNTRY_STATES",
      payload: response.data,
    });
  } catch (err) {
    toast.error("Failed to fetch cities.");
    console.error(err);
  }
};
