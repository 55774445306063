import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { filterService } from "store/actions/servicesAction";
import { useDispatch, useSelector } from "react-redux";
import DropdownComponent from "components/DropdownComponent";
import { getCountryStates } from "store/actions/countryAction";
import { getCities } from "store/actions/countryAction";
import { getCountries } from "store/actions/countryAction";

const ServiceFilterModal = ({
  flag,
  setServiceSearchTitle,
  appliedFilters,
  setAppliedFilters,
  categories,
  modal,
  toggle,
  setCurrentPage,
  itemsPerPage,
  setFlag,
}) => {
  const {
    countriesList: countries,
    states,
    cities,
  } = useSelector((state) => state.countries);
  console.log(countries, "countries>>>>>>>>>");
  const [activeDropdown, setActiveDropdown] = useState(null);
  console.log(activeDropdown, "activeDropdown>>>>>>>>>>");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    category: "",
    country: "",
    state: "",
    city: "",
    minPrice: "",
    maxPrice: "",
    jobSuccess: 0,
  });
  console.log(formData, "formData>>>>>>>>>>");
  const [isJobSuccessApplied, setIsJobSuccessApplied] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "minPrice" || name === "maxPrice") {
      const numericValue = value.replace(/[^0-9.]/g, "");

      if (!isNaN(parseFloat(numericValue))) {
        const formattedValue = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(parseFloat(numericValue));

        setFormData((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));

        e.target.value = formattedValue;
      } else {
        setFormData((prevData) => ({
          ...prevData,
          minPrice: name === "minPrice" ? "" : prevData.minPrice,
          maxPrice: name === "maxPrice" ? "" : prevData.maxPrice,
        }));

        e.target.value = "";
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setActiveDropdown();
    const payload = {
      ...formData,
    };
    if (isJobSuccessApplied) {
      payload.jobSuccess = formData.jobSuccess;
    } else {
      delete payload.jobSuccess;
    }
    console.log(payload, "payload data>>>>>>>");
    setAppliedFilters({ ...payload });
    setServiceSearchTitle("");
    setFlag(true);
    setCurrentPage(1);
    dispatch(filterService(payload, 1, itemsPerPage));
    toggle(); // Close the modal
  };
  const toggleDropdown = useCallback((dropdownName) => {
    setActiveDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  }, []);

  useEffect(() => {
    if (modal) {
      dispatch(getCountries());
      // dispatch(getCountryStates({ country: "US" }));
      // dispatch(getCities({ state: "1456" }));
      if (!flag) {
        setActiveDropdown(null);
        setFormData({
          category: "",
          country: "",
          state: "",
          city: "",
          minPrice: "",
          maxPrice: "",
          jobSuccess: 0,
        });
        setIsJobSuccessApplied(false);
      } else {
        setFormData({ ...appliedFilters });
      }
    }
  }, [modal]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              {/* Work Type */}
              <Col md="12" className="m-auto">
                <Label className="font-weight-bold">Work Type</Label>
                <DropdownComponent
                  list={categories}
                  value={
                    categories?.find((item) => item?.id === formData?.category)
                      ?.label || ""
                  }
                  onChange={(item) =>
                    setFormData({ ...formData, category: item.id })
                  }
                  placeholder="-- Select Category --"
                  isOpen={activeDropdown === "category"}
                  toggleDropdown={() =>
                    setActiveDropdown((prev) =>
                      prev === "category" ? null : "category"
                    )
                  }
                />
              </Col>

              {/* Location */}
              <Col md="12" className="m-auto">
                <Label className="font-weight-bold">Location</Label>
                <DropdownComponent
                  list={countries}
                  value={
                    formData?.country
                      ? countries?.find(
                          (item) => item?.countryCode === formData?.country
                        )?.label || ""
                      : ""
                  }
                  onChange={(item) => {
                    setFormData({
                      ...formData,
                      country: item.countryCode,
                      state: "",
                      city: "",
                    });

                    dispatch(getCountryStates({ country: item.countryCode }));
                  }}
                  placeholder="-- Select Country --"
                  isOpen={activeDropdown === "country"}
                  toggleDropdown={() => toggleDropdown("country")}
                />
              </Col>

              {/* State */}
              <Col md="12" className="m-auto">
                <DropdownComponent
                  list={states}
                  value={
                    formData.state
                      ? states?.find((item) => item?.value === formData?.state)
                          ?.value || ""
                      : ""
                  }
                  onChange={(item) => {
                    setFormData({ ...formData, state: item.value, city: "" });
                    dispatch(getCities({ state: item.id }));
                  }}
                  placeholder="-- Select State/Region --"
                  isOpen={activeDropdown === "state"}
                  toggleDropdown={() => toggleDropdown("state")}
                />
              </Col>

              {/* City */}
              <Col md="12" className="m-auto">
                <DropdownComponent
                  list={cities}
                  value={formData?.city}
                  onChange={(item) => {
                    setFormData({ ...formData, city: item.value });
                  }}
                  placeholder="-- Select City --"
                  isOpen={activeDropdown === "city"} // Controlled open state
                  toggleDropdown={() => toggleDropdown("city")}
                />
              </Col>

              {/* Hourly Rate */}
              <Col md="12" className="m-auto">
                <Label className="font-weight-bold">Hourly Rate</Label>
                <Row className="align-items-center">
                  <Col>
                    <Input
                      type="text"
                      name="minPrice"
                      value={
                        formData.minPrice ? `$${formData.minPrice}` : undefined
                      }
                      onChange={handleInputChange}
                      placeholder="$10"
                    />
                  </Col>
                  <Col xs="auto">
                    <h2 className="fw-bold mb-0">to</h2>
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      name="maxPrice"
                      value={
                        formData.maxPrice ? `$${formData.maxPrice}` : undefined
                      }
                      onChange={handleInputChange}
                      placeholder="$100"
                    />
                  </Col>
                </Row>
              </Col>

              {/* Job Success */}
              <Col md="12" className="m-auto">
                <div className="mt-4">
                  <div className="d-flex justify-content-between">
                    <Label className="font-weight-bold">
                      Job Success {formData.jobSuccess} %
                    </Label>
                    <div>
                      <input
                        type="checkbox"
                        id="apply"
                        checked={isJobSuccessApplied}
                        onChange={(e) =>
                          setIsJobSuccessApplied(e.target.checked)
                        }
                      />
                      <label htmlFor="apply" className="ms-2">
                        Apply
                      </label>
                    </div>
                  </div>
                  <div className="p-3 border rounded w-100">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      step="10"
                      name="jobSuccess"
                      value={formData.jobSuccess}
                      style={{
                        background: `linear-gradient(to right, black ${formData.jobSuccess}%, #e9ecef ${formData.jobSuccess}%)`,
                        accentColor: "black",
                        width: "380px",
                      }}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          jobSuccess: e.target.value,
                        }))
                      }
                    />
                    <span className="ml-2">{formData.jobSuccess}</span>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-center mt-4">
              <Button color="primary" type="submit">
                Apply Filters
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ServiceFilterModal;
