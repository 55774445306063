const initState = {
  allEquipments: [],
  getEquipmentLoader: false,
  addEquipmentLoader: false,
  deleteEquipmentLoader: false,
  updateEquipmentLoader: false,
  totalPages: null,
};

const equipmentsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_EQUIPMENTS":
      return {
        ...state,
        allEquipments: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_EQUIPMENT_LOADER":
      return {
        ...state,
        getEquipmentLoader: payload,
      };
    case "ADD_EQUIPMENT_LOADER":
      return {
        ...state,
        addEquipmentLoader: payload,
      };
    case "DELETE_EQUIPMENT_LOADER":
      return {
        ...state,
        deleteEquipmentLoader: payload,
      };
    case "UPDATE_EQUIPMENT_LOADER":
      return {
        ...state,
        updateEquipmentLoader: payload,
      };
    case "GET_EQUIPMENT_SEARCH_BY_TITLE":
      return {
        ...state,
        allEquipments: payload.data,
        totalPages: payload.totalPages,
      };
    default:
      return {
        ...state,
      };
  }
};
export default equipmentsReducer;
