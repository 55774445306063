const initState = {
  allWorkTypes: [],
  getWorkTypeLoader: false,
  addWorkTypeLoader: false,
  deleteWorkTypeLoader: false,
  updateWorkTypeLoader: false,
  totalPages: null,
};

const workTypeReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_WORKTYPES":
      return {
        ...state,
        allWorkTypes: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_WORKTYPE_LOADER":
      return {
        ...state,
        getWorkTypeLoader: payload,
      };
    case "ADD_WORKTYPE_LOADER":
      return {
        ...state,
        addWorkTypeLoader: payload,
      };
    case "DELETE_WORKTYPE_LOADER":
      return {
        ...state,
        deleteWorkTypeLoader: payload,
      };
    case "UPDATE_WORKTYPE_LOADER":
      return {
        ...state,
        updateWorkTypeLoader: payload,
      };
    case "GET_WORKTYPE_SEARCH_BY_TITLE":
      return {
        ...state,
        allWorkTypes: payload.data,
        totalPages: payload.totalPages,
      };
    default:
      return {
        ...state,
      };
  }
};
export default workTypeReducer;
