import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  addNewEquipment,
  updateEquipment,
  getEquipmentsAction,
} from "store/actions/equipmentAction";
import { updateWorkType } from "store/actions/workTypeActions";
import { addNewWorkType } from "store/actions/workTypeActions";
import { getWorkTypeAction } from "store/actions/workTypeActions";
const EquipmentWorkModal = ({
  modal,
  toggle,
  currentPage,
  itemsPerPage,
  isEdit,
  type,
  item = null,
  SearchTitle,
}) => {
  console.log(item, type, isEdit, "equipment Modal>>>>>>>>>>>>");
  const dispatch = useDispatch();
  const { addWorkTypeLoader, updateWorkTypeLoader } = useSelector(
    (state) => state.worktypes
  );
  const { addEquipmentLoader, updateEquipmentLoader } = useSelector(
    (state) => state.equipments
  );
  const [name, setName] = useState(item?.name || "");
  useEffect(() => {
    if (modal) {
      setName(item?.name || "");
    }
  }, [modal, item]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim()) {
      toast.error(
        type === "equipment"
          ? "Equipment Name is mandatory"
          : "WorkType Name is mandatory"
      );
      return;
    }
    if (isEdit) {
      // Update equipment
      if (type === "equipment") {
        dispatch(
          updateEquipment(item._id, { name }, () => {
            toggle();
            SearchTitle("");
            dispatch(getEquipmentsAction(currentPage, itemsPerPage));
          })
        );
      } else {
        dispatch(
          updateWorkType(item._id, { name }, () => {
            toggle();
            SearchTitle("");
            dispatch(getWorkTypeAction(currentPage, itemsPerPage));
          })
        );
      }
    } else {
      // Add new equipment
      if (type === "equipment") {
        dispatch(
          addNewEquipment({ name }, () => {
            toggle();
            dispatch(getEquipmentsAction(currentPage, itemsPerPage));
            setName("");
          })
        );
      } else {
        dispatch(
          addNewWorkType({ name }, () => {
            toggle();
            dispatch(getWorkTypeAction(currentPage, itemsPerPage));
            setName("");
          })
        );
      }
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <h4>
          {isEdit
            ? type === "equipment"
              ? "Update Equipment"
              : "Update WorkType"
            : type === "equipment"
            ? "Add Equipment"
            : "Add WorkType"}
        </h4>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md="12" className="m-auto">
              <Label className="font-weight-bold">
                {type === "equipment" ? "Equipment" : "WorkType"}
              </Label>
              <FormGroup>
                <Input
                  type="text"
                  placeholder={
                    type === "equipment" ? "Enter Equipment" : "Enter WorkType"
                  }
                  value={name}
                  onChange={handleNameChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              color="primary"
              type="submit"
              disabled={
                isEdit
                  ? type === "equipment"
                    ? updateEquipmentLoader
                    : updateWorkTypeLoader
                  : type === "equipment"
                  ? addEquipmentLoader
                  : addWorkTypeLoader
              }
            >
              {isEdit ? (
                type === "equipment" ? (
                  updateEquipmentLoader ? (
                    <Spinner size="sm" />
                  ) : (
                    "Update"
                  )
                ) : updateWorkTypeLoader ? (
                  <Spinner size="sm" />
                ) : (
                  "Update"
                )
              ) : type === "equipment" ? (
                addEquipmentLoader ? (
                  <Spinner size="sm" />
                ) : (
                  "Add"
                )
              ) : addWorkTypeLoader ? (
                <Spinner size="sm" />
              ) : (
                "Add"
              )}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EquipmentWorkModal;
