/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const EQUIPMENTS = "equipment";
const GET_SEARCH_BY_TITLE = "equipment/search";

export default {
  getEquipments(page, limit) {
    return Repository.get(`${EQUIPMENTS}?page=${page}&limit=${limit}`);
  },

  addEquipment(payload) {
    return Repository.post(`${EQUIPMENTS}`, payload);
  },
  deleteEquipment(id) {
    return Repository.delete(`${EQUIPMENTS}/${id}`);
  },
  updateEquipment(id, payload) {
    return Repository.put(`${EQUIPMENTS}/${id}`, payload);
  },
  equipmentSearchByTitle(title) {
    const requestBody = {
      text: title,
    };
    return Repository.post(`${GET_SEARCH_BY_TITLE}`, requestBody);
  },
};
