import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { FaFilter } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { RxCross2 } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { toast } from "react-toastify";
import { getCategories } from "store/actions/countryAction";
import Filters from "components/Filter";
import debounce from "lodash.debounce";
import FilterModal from "components/Modals/posted-work-order/FilterModal";
import { deleteWorkOrder } from "store/actions/workOrderAction";
import { filterWorkOrder } from "store/actions/workOrderAction";
import { getPostedWorkOrder } from "store/actions/workOrderAction";
import { searchWorkOrder } from "store/actions/workOrderAction";

const PostedWorkOrders = () => {
  const { allWorkOrders, getWorkOrderLoader, totalPages } = useSelector(
    (state) => state.workOrder
  );
  const { categories } = useSelector((state) => state.countries);
  const dispatch = useDispatch();
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedService, setSelectedService] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const itemsPerPage = 10;
  const inputRef = useRef("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen((prevState) => !prevState); // Toggle the modal state
  };
  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };
  const handleDeleteModal = (id) => {
    setSelectedService(id);
    setMessage("Are you sure you want to delete this posted work order?");
    toggleConfirmationModal();
  };
  const handleConfirmationOk = () => {
    dispatch(
      deleteWorkOrder(selectedService, () => {
        if (appliedFilters) {
          dispatch(filterWorkOrder(appliedFilters, currentPage, itemsPerPage));
        } else if (searchQuery) {
          debouncedUpdate();
        } else {
          dispatch(getPostedWorkOrder(currentPage, itemsPerPage));
        }
      })
    );
    toggleConfirmationModal();
  };
  const handleConfirmationCancel = () => {
    toggleConfirmationModal();
  };
  const debouncedUpdate = useCallback(
    debounce(() => {
      const trimmedText = inputRef.current.trim();
      if (trimmedText) {
        dispatch(
          searchWorkOrder({ text: trimmedText }, currentPage, itemsPerPage)
        ).catch((err) =>
          toast.error("Failed to fetch services. Please try again.")
        );
      } else {
        setCurrentPage(1);
        dispatch(getPostedWorkOrder(1, itemsPerPage));
      }
    }, 1000),
    [currentPage, itemsPerPage, setAppliedFilters, dispatch]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    inputRef.current = value;
    setSearchQuery(value);
    if (appliedFilters) {
      setCurrentPage(1);
      setAppliedFilters(null);
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (appliedFilters) {
      dispatch(filterWorkOrder(appliedFilters, currentPage, itemsPerPage));
    } else if (searchQuery) {
      debouncedUpdate();
    } else {
      dispatch(getPostedWorkOrder(currentPage, itemsPerPage));
      setAppliedFilters(null);
    }
  }, [dispatch, currentPage, itemsPerPage, searchQuery]);
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="text-left">Posted Work Orders</h3>
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="d-flex flex-column flex-lg-row justify-content-start align-items-center">
                    <div className="w-100 w-sm-50">
                      <div
                        className="d-flex align-items-center p-3 border-light"
                        style={{ maxWidth: "800px" }}
                      >
                        <div className="input-group shadow-lg rounded">
                          <span className="input-group-text bg-white border-0">
                            <IoSearchOutline style={{ fontSize: "22px" }} />
                          </span>
                          <input
                            type="text"
                            className="form-control border-0"
                            placeholder="Search by Title"
                            style={{ fontSize: "16px" }}
                            value={searchQuery}
                            onChange={handleInputChange}
                          />
                          <span className="input-group-text bg-white border-0">
                            <RxCross2
                              style={{ fontSize: "18px" }}
                              onClick={() => {
                                setSearchQuery("");
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-10 col-lg-2 d-flex justify-content-end align-items-center">
                    <Button
                      className="rounded-circle"
                      color="primary"
                      onClick={toggleFilterModal} // Open modal on button click
                    >
                      <FaFilter className="text-white" />
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Filters appliedFilters={appliedFilters} />
                </Row>
                {appliedFilters && (
                  <Row>
                    <Col className="d-flex justify-content-end align-items-center py-2">
                      <Button
                        color="primary"
                        onClick={() => {
                          setAppliedFilters(null);
                          setCurrentPage(1);
                        }}
                      >
                        Clear Filter
                      </Button>
                    </Col>
                  </Row>
                )}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Work Title</th>
                    <th scope="col">Image</th>
                    <th scope="col">Buyer Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">$ Budget</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {getWorkOrderLoader ? (
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {allWorkOrders && allWorkOrders?.length > 0 ? (
                      allWorkOrders?.map((job, index) => (
                        <tr key={index}>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {job?.title || ""}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <img
                                  src={job?.user?.profileImage}
                                  alt="serviceImage"
                                  height={40}
                                  width={40}
                                  className="mb-0 text-sm"
                                />
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {job?.user?.firstName +
                                    " " +
                                    job?.user?.lastName || ""}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {job?.user?.address || "N/A"}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {job?.budgetSystem === "fixed"
                                    ? `fixed $${job?.fixedRate || "$ 0"}`
                                    : `$${job?.fromRate || 0}/hr-$${
                                        job?.toRate || "$0"
                                      }/hr`}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {job?.status}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Button
                              color="danger"
                              className="mx-2"
                              size="sm"
                              onClick={() => handleDeleteModal(job._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {allWorkOrders && allWorkOrders?.length === 0 && (
                            <p className="mb-0 text-sm">No Service found.</p>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      forcePage={currentPage - 1}
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>

      {/* Filter Modal */}
      <FilterModal
        setSearchQuery={setSearchQuery}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        categories={categories}
        modal={isFilterModalOpen}
        toggle={toggleFilterModal}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
      />
      <ConfirmationModal
        modal={confirmationModal}
        toggle={toggleConfirmationModal}
        userId={selectedService}
        message={message}
        onConfirm={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
      />
    </>
  );
};

export default PostedWorkOrders;
